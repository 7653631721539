export default class SiteHeader {
  constructor() {

    const header = document.querySelector('.site-header');
    let scrollTop = 100;

    window.addEventListener('scroll', () => {
      if (window.scrollY > scrollTop) {
        header.classList.add('lg:py-4');
        header.classList.remove('lg:py-16');
      }
      else {
        header.classList.remove('lg:py-4');
        header.classList.add('lg:py-16');
      }
    });
  }
}
