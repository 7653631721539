// There is nothing to do with this class:
// it is used for all iframes on the website to be responsive by default.
// npm module: https://www.npmjs.com/package/reframe.js#noframe
const reframe = require('reframe.js/dist/reframe');

export default class Reframe {
  constructor(context) {
    context.querySelectorAll('.site-content iframe:not([id^="gform"]):not(.google-map)').forEach(el => reframe(el));
  }
}
