/*eslint-disable */
// Set webpack public path for async loading.
__webpack_public_path__ = window.themeUrl + 'dist/';

// workaround for ie11 and dynamic import
// @see https://github.com/babel/babel/issues/10140
import 'core-js/modules/es.array.iterator';

// DOM polyfills are not part of core-js.
import 'classlist.js';
import 'element-closest';
import 'objectFitPolyfill';

import SmoothAnchors from 'utils/helpers/smooth-anchors';
import Reframe from 'utils/helpers/reframe';
import SiteHeader from 'components/site-header/site-header';
import './main.css';

// import './ajax.js';

objectFitPolyfill();

const body = document.body;

function logModuleLoadFailed(error) {
  console.warn('An error occurred while loading the component');
  console.error(error);
}

new SiteHeader();
// new MiniHeader();

// We will save instances of some modules in an array so that we can clean them up when going
// to another page with ajax. This is needed for the page header which add some animation
// on the homepage. We don't want to have the animation running in background when going to another page.
let modulesToClear = []

function initStimulPlugins(context) {
  // Init small helpers.
  new SmoothAnchors(context);
  new Reframe(context);

  // Load some components javascript files.
  if (context.querySelector('[data-gmap]')) {
    import(/* webpackChunkName: "gmaps" */ 'components/gmaps/gmaps').then(Gmaps => {
      new Gmaps.default(context);
    }).catch(logModuleLoadFailed);
  }
  if (context.querySelector('[data-lightbox]')) {
    import(/* webpackChunkName: "lightbox" */ 'components/lightbox/lightbox').then(Lightbox => {
      new Lightbox.default(context);
    }).catch(logModuleLoadFailed);
  }
  if (context.querySelector('[data-menu-toggle]')) {
    import(/* webpackChunkName: "menu-toggle" */ 'components/menu-toggle/menu-toggle').then(MenuToggle => {
      new MenuToggle.default(context);
    }).catch(logModuleLoadFailed);
  }
  if (context.querySelector('[data-slideshow]')) {
    import(/* webpackChunkName: "slideshow" */ 'components/slideshow/slideshow').then(Slideshow => {
      new Slideshow.default(context);
    }).catch(logModuleLoadFailed);
  }
  if (context.querySelector('[data-slideshow-testimonial]')) {
    import(/* webpackChunkName: "slideshow-testimonial" */ 'modules/Testimonials/Testimonials').then(SlideshowTestimonials => {
      new SlideshowTestimonials.default(context);
    }).catch(logModuleLoadFailed);
  }
  if (context.querySelector('[data-nursery-list]')) {
    import(/* webpackChunkName: "nursery-list" */ 'modules/NurseryList/NurseryList').then(NurseryList => {
      new NurseryList.default(context);
    }).catch(logModuleLoadFailed);
  }
  if (context.querySelector("[data-comment-reply]")) {
    import(/* webpackChunkName: "comment" */ 'components/comment/comment').then(Comment => {
        new Comment.default(context);
      }).catch(logModuleLoadFailed);
  }
  if (context.querySelector(".front-page-header")) {
    import(/* webpackChunkName: "front-page-header" */ 'modules/FrontPageHeader/FrontPageHeader').then(FrontPageHeader => {
      context.querySelectorAll(".front-page-header").forEach((el) => {
        // Add the page header instance to the modules to clear array.
        modulesToClear.push(new FrontPageHeader.default(el));
      })
    }).catch(logModuleLoadFailed);
  }
  if (context.querySelector("[data-mini-header]")) {
    import(/* webpackChunkName: "mini-header" */ 'components/mini-header/mini-header').then(MiniHeader => {
        new MiniHeader.default(context.querySelector("[ddata-mini-header]"));
      }).catch(logModuleLoadFailed);
  }
  if (context.querySelector("[data-map]")) {
    import(/* webpackChunkName: "map-search" */ 'modules/Search/Search').then(Search => {
        new Search.default(context.querySelector("[data-map]"));
      }).catch(logModuleLoadFailed);
  }

  // Load extra modules
  if (context.querySelector('.rellax')) {
    import(/* webpackChunkName: "parallax" */ 'utils/helpers/parallax').then(Parallax => {
      new Parallax.default(context);
    }).catch(logModuleLoadFailed);
  }
  if (context.querySelector('[data-sticky-header]')) {
    import(/* webpackChunkName: "sticky-header" */ 'utils/helpers/sticky-header').then(StickyHeader => {
      new StickyHeader.default(context);
    }).catch(logModuleLoadFailed);
  }
  if (context.querySelector('[data-animation]')) {
    import(/* webpackChunkName: "scroll-animation" */ 'utils/helpers/scroll-animation').then(ScrollAnimation => {
      new ScrollAnimation.default(context);
    }).catch(logModuleLoadFailed);
  }
  if (context.querySelector('[data-imagesloaded]')) {
    import(/* webpackChunkName: "imagesloaded" */ 'utils/helpers/imagesloaded').then(ImagesLoaded => {
      new ImagesLoaded.default(context);
    }).catch(logModuleLoadFailed);
  }

  // Modules & components js
  if (context.querySelector('.media-item')) {
    import(/* webpackChunkName: "MediaItem" */ 'components/media-item/media-item').then(MediaItem => {
      new MediaItem.default(context);
    }).catch(logModuleLoadFailed);
  }
  if (context.querySelector('.gform_wrapper')) {
    import(/* webpackChunkName: "Form" */ 'components/form/form').then(Form => {
      new Form.default(context);
    }).catch(logModuleLoadFailed);
  }
}

initStimulPlugins(document.querySelector('html'));
// Make function globally available.
window.initStimulPlugins = initStimulPlugins;

window.clearStimulPlugins = function() {
  modulesToClear.forEach((module) => {
    module.destroy();
  });
  modulesToClear = []
}
